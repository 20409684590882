import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_assignee = _resolveComponent("filter-assignee")!
  const _component_dropdown_filter_checkbox = _resolveComponent("dropdown-filter-checkbox")!

  return (_openBlock(), _createBlock(_component_dropdown_filter_checkbox, {
    name: _ctx.label,
    "option-key": "fullName",
    "option-label": "fullName",
    "search-placeholder": "Search assignee",
    "dropdown-class": "filter-assignee"
  }, {
    default: _withCtx(({ value, valuePlus, dropdownValue }) => [
      _renderSlot(_ctx.$slots, "default", {
        value: value,
        valuePlus: valuePlus,
        dropdownValue: dropdownValue
      })
    ]),
    filter: _withCtx(({ close }) => [
      _createVNode(_component_filter_assignee, _mergeProps({ ..._ctx.$attrs, ..._ctx.$props }, {
        onOnApply: close,
        onOnCancel: close
      }), {
        "before-filter": _withCtx(() => [
          _renderSlot(_ctx.$slots, "before-filter")
        ]),
        _: 2
      }, 1040, ["onOnApply", "onOnCancel"])
    ]),
    _: 3
  }, 8, ["name"]))
}