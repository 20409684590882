import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_assignee = _resolveComponent("tm-assignee")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_filter_checkbox = _resolveComponent("filter-checkbox")!

  return (_openBlock(), _createBlock(_component_filter_checkbox, {
    "option-key": "fullName",
    "option-label": "fullName",
    "search-placeholder": _ctx.searchPlaceholder,
    "visible-more": !!_ctx.showMoreOptions,
    searchable: ""
  }, {
    option: _withCtx(({ option }) => [
      _createVNode(_component_tm_assignee, {
        assignee: option,
        "custom-size": "32px",
        "avatar-icon": option.avatarIcon,
        "placeholder-custom-size": "16px",
        overflow: ""
      }, null, 8, ["assignee", "avatar-icon"])
    ]),
    "before-filter": _withCtx(() => [
      _renderSlot(_ctx.$slots, "before-filter")
    ]),
    "show-more-field": _withCtx(({ input, visibleItems, showBtn }) => [
      _createVNode(_component_tm_field, _mergeProps({ "model-value": null }, _ctx.showMoreProps, {
        type: "assignee",
        options: _ctx.getFilteredOptions(visibleItems),
        "with-unassigned-option": false,
        autofocus: "",
        "onUpdate:modelValue": input,
        onBlur: showBtn
      }), null, 16, ["options", "onUpdate:modelValue", "onBlur"])
    ]),
    _: 3
  }, 8, ["search-placeholder", "visible-more"]))
}