
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import FilterCheckbox from '@/components/shared/filters/FilterCheckbox.vue'
import TmAssignee from '@/components/shared/TmAssignee.vue'
import isEqual from 'lodash/isEqual'

type FieldOptions = {
  uid: string;
  fullName: string;
  avatar?: string;
}
type FieldProps = {
  placeholder?: string;
}

export default defineComponent({
  name: 'FilterAssignee',
  components: { FilterCheckbox, TmAssignee },
  props: {
    showMoreOptions: {
      type: Array as PropType<FieldOptions[]>,
    },
    showMoreProps: {
      type: Object as PropType<FieldProps>,
    },
    searchPlaceholder: {
      type: String,
      default: 'Search assignee',
    },
  },
  setup(props) {
    const showMoreField = ref()

    const getFilteredOptions = (visibleItems: any[]) => (
      props.showMoreOptions?.filter((el: any) => !visibleItems.some((vItem: any) => isEqual(el, vItem)))
    )

    return {
      showMoreField,
      getFilteredOptions,
    }
  },
})
