
import { defineComponent } from 'vue'
import DropdownFilterCheckbox from '@/components/shared/filters/dropdown/DropdownFilterCheckbox.vue'
import FilterAssignee from '@/components/shared/filters/FilterAssignee.vue'

export default defineComponent({
  name: 'DropdownFilterAssignee',
  components: { FilterAssignee, DropdownFilterCheckbox },
  props: {
    label: {
      type: String,
      default: 'Assignee',
    },
    disableMenu: {
      type: Boolean,
    },
  },
})
